import { get, post } from './client';
import config from '../config';

export async function login(email: string, password: string): Promise<any> {
  return post(`${config.apiUrl}/admin/login`, { email, password });
};

export async function syncAccountPipedrive(id: number): Promise<void> {
  return get(`${config.apiUrl}/pipedrive/sync-single?id=${id}`);
}

interface getLoggerLevelReponse {
  level: string;
}

export async function getLoggerLevel(): Promise<getLoggerLevelReponse> {
  return get(`${config.apiUrl}/manage/logger`);
}

interface getQueueReponse {
  data: {
    repeatableCount: number;
    activeCount: number;
    completedCount: number;
    failedCount: number;
    waitingCount: number;
  }
}

export async function getQueue(name: string): Promise<getQueueReponse> {
  return get(`${config.apiUrl}/manage/queue/${name}`);
}
