import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import favicon from '../images/favicon.png';

const Navigation = () => (
  <Navbar bg="dark" variant="dark" expand="lg">
    <Navbar.Brand as={Link} to="/">
      <img
        alt="aphadol"
        src={favicon}
        width="30"
        height="30"
        className="d-inline-block align-top"
      />{' '}
      Aphadol
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <NavDropdown title="View" id="collasible-nav-dropdown">
          <NavDropdown.Item as={Link} to="/view/users">Users</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/view/accounts">Accounts</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/view/events">Events</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/view/social-media-profiles">Social media profiles</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to="/view/tracking-log">Tracking log</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/view/event-logs">Event logs</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to="/view/visitors">Visitors</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Report" id="collasible-nav-dropdown">
          <NavDropdown.Item as={Link} to="/report/default">Default</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to="/report/csm">CSM</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/report/marketing">Marketing</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to="/report/paying-customers">Paying customers</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Nav>
        <NavDropdown title="Manage" id="collasible-nav-dropdown">
          <NavDropdown.Item as={Link} to="/manage/admins">Admins</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to="/manage/logger">Logger</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/manage/queue">Queue</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Link} to="/manage/graphiql">GraphiQL</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link as={Link} to="/developers">Developers</Nav.Link>
        <Nav.Link as={Link} to="/about">About</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default React.memo(Navigation);
