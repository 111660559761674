/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type HomeContainerQueryVariables = {
    todayStart?: unknown | null | undefined;
    todayEnd?: unknown | null | undefined;
};
export type HomeContainerQueryResponse = {
    readonly registrationsToday: {
        readonly totalCount: number;
    } | null;
    readonly profilesConnectedToday: {
        readonly totalCount: number;
    } | null;
    readonly postsCreatedToday: {
        readonly totalCount: number;
    } | null;
    readonly statisticsPerDay: ReadonlyArray<{
        readonly id: string;
        readonly _id: string;
        readonly dateAt: string;
        readonly statistics: ReadonlyArray<{
            readonly name: string;
            readonly value: number;
        } | null>;
    } | null> | null;
};
export type HomeContainerQuery = {
    readonly response: HomeContainerQueryResponse;
    readonly variables: HomeContainerQueryVariables;
};



/*
query HomeContainerQuery(
  $todayStart: GraphQLDateTime
  $todayEnd: GraphQLDateTime
) {
  registrationsToday: accounts(first: 1, dateFrom: $todayStart, dateTo: $todayEnd) {
    totalCount
  }
  profilesConnectedToday: eventLogs(first: 1, dateFrom: $todayStart, dateTo: $todayEnd, eventNames: ["add_facebook_profile", "add_instagram_profile", "add_linkedin_profile", "add_twitter_profile", "add_pinterest_profile"]) {
    totalCount
  }
  postsCreatedToday: eventLogs(first: 1, dateFrom: $todayStart, dateTo: $todayEnd, eventNames: ["create_facebook_post", "create_instagram_post", "create_linkedin_post", "create_twitter_post", "create_pinterest_post"]) {
    totalCount
  }
  statisticsPerDay(days: 14) {
    id
    _id
    dateAt
    statistics(names: ["accountsCount", "createdPostsCount", "createdFacebookPostsCount", "createdInstagramPostsCount", "createdLinkedinPostsCount", "createdTwitterPostsCount", "createdPinterestPostsCount"]) {
      name
      value
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "todayEnd"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "todayStart"
},
v2 = {
  "kind": "Variable",
  "name": "dateFrom",
  "variableName": "todayStart"
},
v3 = {
  "kind": "Variable",
  "name": "dateTo",
  "variableName": "todayEnd"
},
v4 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": "registrationsToday",
    "args": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "concreteType": "AccountConnection",
    "kind": "LinkedField",
    "name": "accounts",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": "profilesConnectedToday",
    "args": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "kind": "Literal",
        "name": "eventNames",
        "value": [
          "add_facebook_profile",
          "add_instagram_profile",
          "add_linkedin_profile",
          "add_twitter_profile",
          "add_pinterest_profile"
        ]
      },
      (v4/*: any*/)
    ],
    "concreteType": "EventLogConnection",
    "kind": "LinkedField",
    "name": "eventLogs",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": "postsCreatedToday",
    "args": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "kind": "Literal",
        "name": "eventNames",
        "value": [
          "create_facebook_post",
          "create_instagram_post",
          "create_linkedin_post",
          "create_twitter_post",
          "create_pinterest_post"
        ]
      },
      (v4/*: any*/)
    ],
    "concreteType": "EventLogConnection",
    "kind": "LinkedField",
    "name": "eventLogs",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "days",
        "value": 14
      }
    ],
    "concreteType": "StatisticsPerDay",
    "kind": "LinkedField",
    "name": "statisticsPerDay",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "names",
            "value": [
              "accountsCount",
              "createdPostsCount",
              "createdFacebookPostsCount",
              "createdInstagramPostsCount",
              "createdLinkedinPostsCount",
              "createdTwitterPostsCount",
              "createdPinterestPostsCount"
            ]
          }
        ],
        "concreteType": "StatisticsValue",
        "kind": "LinkedField",
        "name": "statistics",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": "statistics(names:[\"accountsCount\",\"createdPostsCount\",\"createdFacebookPostsCount\",\"createdInstagramPostsCount\",\"createdLinkedinPostsCount\",\"createdTwitterPostsCount\",\"createdPinterestPostsCount\"])"
      }
    ],
    "storageKey": "statisticsPerDay(days:14)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeContainerQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HomeContainerQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "891920214a4a8f4a172f2830877d13d6",
    "id": null,
    "metadata": {},
    "name": "HomeContainerQuery",
    "operationKind": "query",
    "text": "query HomeContainerQuery(\n  $todayStart: GraphQLDateTime\n  $todayEnd: GraphQLDateTime\n) {\n  registrationsToday: accounts(first: 1, dateFrom: $todayStart, dateTo: $todayEnd) {\n    totalCount\n  }\n  profilesConnectedToday: eventLogs(first: 1, dateFrom: $todayStart, dateTo: $todayEnd, eventNames: [\"add_facebook_profile\", \"add_instagram_profile\", \"add_linkedin_profile\", \"add_twitter_profile\", \"add_pinterest_profile\"]) {\n    totalCount\n  }\n  postsCreatedToday: eventLogs(first: 1, dateFrom: $todayStart, dateTo: $todayEnd, eventNames: [\"create_facebook_post\", \"create_instagram_post\", \"create_linkedin_post\", \"create_twitter_post\", \"create_pinterest_post\"]) {\n    totalCount\n  }\n  statisticsPerDay(days: 14) {\n    id\n    _id\n    dateAt\n    statistics(names: [\"accountsCount\", \"createdPostsCount\", \"createdFacebookPostsCount\", \"createdInstagramPostsCount\", \"createdLinkedinPostsCount\", \"createdTwitterPostsCount\", \"createdPinterestPostsCount\"]) {\n      name\n      value\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '651dbb4ec806c2f5839f1db52a53b82d';
export default node;
