class ServiceError extends Error {
  public name: string;
  public message: string;
  public status: string;
  public statusText: string;
  public response: string;

  constructor({ status, statusText, response }: any) {
    super();
    console.log('response', response);
    this.name = 'ServiceError';
    this.message = `${status} ${statusText}`;
    this.status = status;
    this.statusText = statusText;
    this.response = response;
  }
}

async function handleErrors(response: any): Promise<any> {
  if (!response.ok) {
    console.log('error!');
    throw new ServiceError({
      status: response.status,
      statusText: response.statusText,
      response: await response.json(),
    });
  }
  return response;
}

function handleSuccess(response: any) {
  if (response.headers.get('Content-Type').startsWith('application/json')) {
    return response.json();
  } else {
    return response.text();
  }
}

export async function get(endpoint: string): Promise<any> {
  return fetch(endpoint)
    .then(handleErrors)
    .then(handleSuccess);
};

export async function post(endpoint: string, data: object): Promise<any> {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(handleErrors)
    .then(handleSuccess);
};
