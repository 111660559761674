import React from 'react';
import { Route } from 'react-router-dom';
import getLazyComponent from 'utils/getLazyComponent';

const Users = getLazyComponent(() => import('./view/Users'));
const User = getLazyComponent(() => import('./view/User'));
const Accounts = getLazyComponent(() => import('./view/Accounts'));
const Account = getLazyComponent(() => import('./view/Account'));
const Tracking = getLazyComponent(() => import('./view/Tracking'));
const EventLogs = getLazyComponent(() => import('./view/EventLog'));
const Events = getLazyComponent(() => import('./view/Events'));
const SocialMediaProfiles = getLazyComponent(() => import('./view/SocialMediaProfiles'));

const View: React.FC = () => {
  return (
    <>
      <Route path="/view/users" component={Users} />
      <Route path="/view/user/:id" component={User} />
      <Route path="/view/accounts" component={Accounts} />
      <Route path="/view/account/:id" component={Account} />
      <Route path="/view/tracking-log" component={Tracking} />
      <Route path="/view/event-logs" component={EventLogs} />
      <Route path="/view/events" component={Events} />
      <Route path="/view/social-media-profiles" component={SocialMediaProfiles} />
    </>
  );
}

export default View;
