import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  description: string;
}

const SEO: React.FC<Props> = (props) => (
  <>
    <Helmet>
      <title>{props.title} | Aphadol</title>
      <meta name="description" content={props.description} />
    </Helmet>
    {props.children}
  </>
);

export default SEO;
