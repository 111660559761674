import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import SEO from '../components/SEO';
import { login } from '../api/services';

interface Props {
  onSuccess: (user: any) => void;
}

const Login: React.FC<Props> = ({ onSuccess }: Props) => {
  const [ isValidated, setIsValidated ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ email, setEmail ] = useState('');
  const [ password, setPassword  ] = useState('');

  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setIsValidated(true);

    const form = event.currentTarget;

    if (form.checkValidity()) {
      login(email, password)
        .then((data: any) => {
          onSuccess(data.data);
        })
        .catch((error) => {
          setErrorMessage(error.response.error);
          setIsValidated(false);
        });
    }
  };

  return (
    <SEO title="Login" description="">
      <Container className="main" style={{ marginTop: 50 }}>
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            {
              errorMessage !== null &&
              <Alert variant={'danger'}>
                {errorMessage}
              </Alert>
            }
            <Form noValidate validated={isValidated} onSubmit={onSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control required type="email" placeholder="Enter email" value={email} onChange={onEmailChange} />
                <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control required type="password" placeholder="Password" value={password} onChange={onPasswordChange} />
                <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Login;
