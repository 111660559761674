import React from 'react';
import { Route } from 'react-router-dom';
import getLazyComponent from 'utils/getLazyComponent';

const GraphiQL = getLazyComponent(() => import('./manage/Graphiql'));
const Logger = getLazyComponent(() => import('./manage/Logger'));
const Queue = getLazyComponent(() => import('./manage/Queue'));
const Admins = getLazyComponent(() => import('./manage/Admins'));

const View: React.FC = () => {
  return (
    <>
      <Route path="/manage/graphiql" component={GraphiQL} />
      <Route path="/manage/logger" component={Logger} />
      <Route path="/manage/queue" component={Queue} />
      <Route path="/manage/admins" component={Admins} />
    </>
  );
}

export default View;
