import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BarLoader from '../../components/BarLoader';

const HomeLoader: React.FC<any> = (props: any) => (
  <>
    <Row>
      <Col>
        <h2 className="text-center">Today</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <Card.Title className="text-center">Registrations</Card.Title>
            <div className="text-center">
              <BarLoader style={{ width: 42, height: 76, display: 'inline-block' }} />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Body>
            <Card.Title className="text-center">Posts created</Card.Title>
            <div className="text-center">
              <BarLoader style={{ width: 120, height: 76, display: 'inline-block' }} />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Body>
            <Card.Title className="text-center">Profiles connected</Card.Title>
            <div className="text-center">
              <BarLoader style={{ width: 95, height: 76, display: 'inline-block' }} />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <h2 className="text-center">Registrations</h2>
        <BarLoader style={{ width: '100%', height: 400, display: 'inline-block' }} />
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <h2 className="text-center">Posts created</h2>
        <BarLoader style={{ width: '100%', height: 400, display: 'inline-block' }} />
      </Col>
    </Row>
  </>
);

export default HomeLoader;
