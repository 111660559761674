import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CustomLineGraph from '../../components/CustomLineGraph';
import {
  formatDate,
} from '../../utils/functions';

const Home: React.FC<any> = (props: any) => (
  <>
    <Row>
      <Col>
        <h2 className="text-center">Today</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <Card.Title className="text-center">Registrations</Card.Title>
            <div className="text-center">
              <strong style={{ fontSize: 64 }}>{props.registrationsToday.totalCount}</strong>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Body>
            <Card.Title className="text-center">Posts created</Card.Title>
            <div className="text-center">
              <strong style={{ fontSize: 64 }}>{props.postsCreatedToday.totalCount}</strong>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Body>
            <Card.Title className="text-center">Profiles connected</Card.Title>
            <div className="text-center">
              <strong style={{ fontSize: 64 }}>{props.profilesConnectedToday.totalCount}</strong>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <h2 className="text-center">Registrations</h2>
        <p className="lead text-center">Number of all registrations in last 14 days is <b>{props.statisticsPerDay.filter((day: any) => day.statistics.length > 0).map((day: any) => day.statistics.find((s: any) => s.name === 'accountsCount').value).reduce((accumulator: number, a: number) => accumulator + a, 0)}</b>. In the graph below you can see how it has evolved over time.</p>
        <CustomLineGraph height={400} data={props.statisticsPerDay.filter((day: any) => day.statistics.length > 0).reverse().map((day: any) => {
          return {
            name: formatDate(new Date(day.dateAt), 'dd.MM.YYYY'),
            value: day.statistics.find((s: any) => s.name === 'accountsCount').value,
          }
        })} lines={[{ name: 'Registrations', dataKey: 'value' }]} />
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <h2 className="text-center">Posts created</h2>
        <p className="lead text-center">Number of all created posts in last 14 days is <b>{props.statisticsPerDay.filter((day: any) => day.statistics.length > 0).map((day: any) => day.statistics.find((s: any) => s.name === 'createdPostsCount').value).reduce((accumulator: number, a: number) => accumulator + a, 0)}</b>. In the graph below you can see how it has evolved over time.</p>
        <CustomLineGraph height={400} data={props.statisticsPerDay.filter((day: any) => day.statistics.length > 0).reverse().map((day: any) => {
          return {
            name: formatDate(new Date(day.dateAt), 'dd.MM.YYYY'),
            posts: day.statistics.find((s: any) => s.name === 'createdPostsCount').value,
            facebookPosts: day.statistics.find((s: any) => s.name === 'createdFacebookPostsCount').value,
            instagramPosts: day.statistics.find((s: any) => s.name === 'createdInstagramPostsCount').value,
            linkedinPosts: day.statistics.find((s: any) => s.name === 'createdLinkedinPostsCount').value,
            twitterPosts: day.statistics.find((s: any) => s.name === 'createdTwitterPostsCount').value,
            pinterestPosts: day.statistics.find((s: any) => s.name === 'createdPinterestPostsCount').value,
          }
        })} lines={[
          { name: 'Posts', dataKey: 'posts' },
          { name: 'Facebook posts', dataKey: 'facebookPosts' },
          { name: 'Instagram posts', dataKey: 'instagramPosts' },
          { name: 'LinkedIn posts', dataKey: 'linkedinPosts' },
          { name: 'Twitter posts', dataKey: 'twitterPosts' },
          { name: 'Pinterest posts', dataKey: 'pinterestPosts' },
        ]} />
      </Col>
    </Row>
  </>
);

const HomeContainer = () => {
  const todayStart = formatDate(new Date(), 'YYYY-MM-dd 00:00:00');
  const todayEnd = formatDate(new Date(), 'YYYY-MM-dd 23:59:59');
  const data = useLazyLoadQuery<any>(
    graphql`
      query HomeContainerQuery($todayStart: GraphQLDateTime, $todayEnd: GraphQLDateTime) {
        registrationsToday: accounts(first: 1, dateFrom: $todayStart, dateTo: $todayEnd) {
          totalCount
        }
        profilesConnectedToday: eventLogs(first: 1, dateFrom: $todayStart, dateTo: $todayEnd, eventNames: [ "add_facebook_profile", "add_instagram_profile", "add_linkedin_profile", "add_twitter_profile", "add_pinterest_profile" ]) {
          totalCount
        }
        postsCreatedToday: eventLogs(first: 1, dateFrom: $todayStart, dateTo: $todayEnd, eventNames: [ "create_facebook_post", "create_instagram_post", "create_linkedin_post", "create_twitter_post", "create_pinterest_post" ]) {
          totalCount
        }
        statisticsPerDay(days: 14) {
          id
          _id
          dateAt
          statistics(names: [ "accountsCount", "createdPostsCount", "createdFacebookPostsCount", "createdInstagramPostsCount", "createdLinkedinPostsCount", "createdTwitterPostsCount", "createdPinterestPostsCount" ]) {
            name
            value
          }
        }
      }`, {
      todayStart,
      todayEnd,
    },
  );
  
  return (
    <Home {...data} />
  );
};

export default HomeContainer;
