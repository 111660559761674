import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts';

interface ChartData {
  name?: string;
  [x: string]: unknown;
}

interface ChartLines {
  name: string;
  dataKey: string;
  fill?: string;
}

interface Props {
  data: Array<ChartData>;
  lines: Array<ChartLines>;
  height?: number;
}

const getRandomColor = () => {
  const randomHex = Math.floor(Math.random()*16777215).toString(16);
  return `#${randomHex}`;
}

const LineGraph: React.FC<Props> = ({ data, lines, ...props }: Props) => {
  return (
    <ResponsiveContainer width="100%" {...props}>
      <LineChart data={data} margin={{ top: 20, bottom: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {
          lines.map((line) => (
            <Line type="monotone" key={line.name} dataKey={line.dataKey} stroke={'fill' in line ? line.fill : getRandomColor()} name={line.name} />
          ))
        }
      </LineChart>
    </ResponsiveContainer>
  );
}

LineGraph.defaultProps = {
  height: 300,
}

export default LineGraph;
