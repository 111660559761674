import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import { RelayEnvironmentProvider } from 'react-relay';
import environment from './environment';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import Home from 'pages/Home';
import Login from 'pages/Login';
import View from 'pages/View';
import Manage from 'pages/Manage';
import getLazyComponent from 'utils/getLazyComponent';
import './App.css';

const PageNotFound = getLazyComponent(() => import('pages/PageNotFound'));
const About = getLazyComponent(() => import('pages/About'));
const Developers = getLazyComponent(() => import('pages/Developers'));
const Report = getLazyComponent(() => import('pages/Report'));

function App() {
  const [ token, setToken ] = useState(localStorage.getItem('user-token'));
  const [ redirect, setRedirect ] = useState(false);

  const onTokenChange = (token: string) => {
    localStorage.setItem('user-token', token);
    setToken(token);
    setRedirect(true);
  };

  useEffect(() => setRedirect(false), [ redirect ]);

  return (
    <RelayEnvironmentProvider environment={environment}>
      <Router>
        {
          token === null ? (
            <Switch>
              <Route path="/login" render={() => (
                <Login onSuccess={(user: any) => onTokenChange(user.token)} />
              )} />
              <Redirect to="/login" />
            </Switch>
          ) : (
            <>
              <Navigation />

              <main role="main" className="flex-shrink-0" style={{ marginTop: 25, marginBottom: 25, minHeight: 'calc(100vh - 162px)' }}>
                
                  <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/view/*" component={View} />
                    <Route path="/about" component={About} />
                    <Route path="/developers" component={Developers} />
                    <Route path="/manage/*" component={Manage} />
                    <Route path="/report" component={Report} />

                    <Route path="/" component={PageNotFound} />
                  </Switch>
                
              </main>

              <Footer />
            </>
          )
        }
        {
          redirect ? <Redirect to="/" /> : null
        }
      </Router>
    </RelayEnvironmentProvider>
  );
}

export default App;
