import React, { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from 'components/SEO';
import HomeLoader from './home/HomeLoader';
import HomeContainer from './home/HomeContainer';

const Home: React.FC = () => {
  return (
    <SEO title="Dashboard" description="">
      <Container fluid>
        <Row>
          <Col>
            <Suspense fallback={<HomeLoader />}>
              <HomeContainer />
            </Suspense>
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Home;
